import React from "react";
import FOOTER from '../imgs/FOOTER.png'
import './Footer.css';
import ScrollToTopButton from "./ScrollToTopButton";

const Footer = () => {

    return(
        <div className="Footer">
            <img src={FOOTER} alt="Footer" className="FinalDaPagina" />
           
        </div>
    )
}

export default Footer;