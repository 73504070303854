import React from 'react';
import Header from '../components/Header';
import CardsComponent from '../components/CardComponents'; // Atualize o caminho se necessário
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="Home">
      <div className='Header'>
        <Header />
      </div>

      <div className="container">
        <CardsComponent /> {/* O CardsComponent é responsável por carregar e exibir os cards */}
      </div>

      <div className='Footer'>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
