import React from 'react';
import './LoadMoreButton.css'; // Se você tiver estilos específicos

const LoadMoreButton = ({ onClick }) => {
    return (
        <div className='container-button'>
            <button className="load-more-button" onClick={onClick}>
                Carregar mais
            </button>
        </div>
    );
};

export default LoadMoreButton;