// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FinalDaPagina{
    display: flex;
    max-width: 100%;
    height: auto;
    

}

@media (max-width: 768px) {
    .FinalDaPagina {
        bottom: 0%;
        width: 100%;
    }
  }

  /* @media (min-width: 1200px) {
    .FinalDaPagina {
        bottom: 0%;
        width: 100%;
    }
  } */`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,YAAY;;;AAGhB;;AAEA;IACI;QACI,UAAU;QACV,WAAW;IACf;EACF;;EAEA;;;;;KAKG","sourcesContent":[".FinalDaPagina{\n    display: flex;\n    max-width: 100%;\n    height: auto;\n    \n\n}\n\n@media (max-width: 768px) {\n    .FinalDaPagina {\n        bottom: 0%;\n        width: 100%;\n    }\n  }\n\n  /* @media (min-width: 1200px) {\n    .FinalDaPagina {\n        bottom: 0%;\n        width: 100%;\n    }\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
