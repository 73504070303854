import React from "react";
import './Header.css';
import HEADER from '../imgs/HEADER.png'



const Header = () => {

    return(
        <div>
            <div className="header">
                <img src={HEADER} alt="Header" className="Cabeçalho"/>
            </div>
        </div>
    )
}

export default Header;