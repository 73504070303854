import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import CardsComponent from '../components/CardComponents'; // Atualize o caminho se necessário
import Footer from '../components/Footer';
import './style.css';
import ScrollToTopButton from '../components/ScrollToTopButton';

const Home = () => {
  const [timeLeft, setTimeLeft] = useState(3600); // 1 hora em segundos
  const [isVotingDisabled, setIsVotingDisabled] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        }
        setIsVotingDisabled(false); // Ativa o botão de votar quando o contador chega a 0
        return 0;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup para evitar vazamentos de memória
  }, []);

  // Formatar o tempo em HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div className="Home">
      <div className="Header">
        <Header />
      </div>

      <div className="container">
      <CardsComponent />
      </div>
      <ScrollToTopButton/>
      <div className="Footer">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
