import React, { useState } from 'react';
import './Card.css';
import Modal from './Modal'; // Certifique-se de que o caminho está correto

const Card = ({ titulo, descricao, image, escola, tutor, integrantes, videoId }) => {
  const [votes, setVotes] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleVote = () => {
    setVotes(votes + 1);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Debugging: Verificar se as props estão corretas
  // console.log('Card Props:', { titulo, descricao, image, escola, tutor, integrantes, videoId });

  return (
    <div className="card-container">
      <div className="card-image-container">
        <img src={image || "https://via.placeholder.com/391x340"} alt={titulo || "Imagem do Card"} />
      </div>
      <div className="card-content">
        <h2>{titulo}</h2>
        <p>{descricao}</p>
        <button className="cardButton" onClick={handleVote}>Votar</button>

        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          titulo={titulo}
          descricao={descricao}
          image={image}
          escola={escola}
          tutor={tutor}
          integrantes={integrantes}
          videoId={videoId}
        />
      </div>
    </div>
  );
};

export default Card;
