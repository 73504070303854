import React, { useState, useEffect, useCallback } from 'react';
import Card from './Card'; // Certifique-se de que o caminho está correto
import './CardComponent.css';
import FilterForm from './FilterForm';
import LoadMoreButton from './LoadMoreButton'; // Importe o componente LoadMoreButton
// const apiUrl = process.env.REACT_APP_API2_URL || 'http://localhost:3010/';
const apiUrl = process.env.REACT_APP_API2_URL || 'https://votacaocode.snctjp.com.br/api';

const CardsComponent = () => {
    const [cards, setCards] = useState([]);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState({ escola: '', search: '', ordem: 'ASC' });
    const [hasMore, setHasMore] = useState(true);
    const [escolas, setEscolas] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Adiciona controle de loading
    const limit = 8;

    // Carrega a lista de escolas ao montar o componente
    useEffect(() => {
        const fetchEscolas = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/accounts/Users/filtroescolas`);
                if (!response.ok) {
                    throw new Error(`Erro na resposta: ${response.statusText}`);
                }
                
                const data = await response.json();
                setEscolas(data);
            } catch (error) {
                console.error('Erro ao carregar lista de escolas:', error);
            }
        };

        fetchEscolas();
    }, []); // Dependência vazia para rodar apenas uma vez após o primeiro render

    // Carrega os cards ao montar o componente
    useEffect(() => {
        loadCards(true);
    }, []); // Dependência vazia para rodar apenas uma vez após o primeiro render

    const fetchThumbnailUrl = async (videoId) => {
        console.log(`Chamando API com VideoId: ${videoId}`);
        try {
            const response = await fetch(`${apiUrl}/api/thumb/${videoId}`);
            const data = await response.json();
            console.log('Resposta da API de Thumbnail:', data);
            return data.thumbnailUrl;
        } catch (error) {
            console.error('Erro ao buscar a URL da thumbnail:', error);
            return '';
        }
    };

    const loadCards = useCallback(async (withFilter = false) => {
        if (withFilter) {
            setCards([]); // Reinicia os cards ao filtrar
            setOffset(0); // Reinicia o offset
            setHasMore(true); // Reinicia a condição de carregamento
        }
    
        if (!hasMore || isLoading) return; // Impede carregamento se já não há mais ou se está carregando
    
        setIsLoading(true); // Define como carregando para evitar múltiplas requisições simultâneas
    
        try {
            const currentOffset = withFilter ? 0 : offset;
            console.log(apiUrl)
            const response = await fetch(
                `${apiUrl}/api/accounts/Users/vermais?escola=${filters.escola}&search=${filters.search}&ordem=${filters.ordem}&limit=${limit}&offset=${currentOffset}`
            );
    
            if (!response.ok) {
                throw new Error(`Erro na resposta: ${response.statusText}`);
            }
    
            const data = await response.json();
            console.log('Dados recebidos:', data);
    
            const cardsWithThumbnails = await Promise.all(data.rows.map(async (card) => {
                const thumbnailUrl = await fetchThumbnailUrl(card.VideoId);
                return { ...card, thumbnailUrl };
            }));
    
            console.log('Cards com Thumbnails:', cardsWithThumbnails);
    
            setCards(prevCards => {
                const newCards = cardsWithThumbnails.filter(newCard => {
                    const exists = prevCards.some(card => {
                        // Verifique se o id não é nulo
                        if (newCard.id && card.id) {
                            return card.id === newCard.id;
                        }
                        return false;  // Se o id for null, considere que não existe duplicata
                    });
                    console.log(`Verificando card: ${newCard.id} - Existe: ${exists}`);
                    return !exists;
                });
            
                console.log('Novos cards a adicionar:', newCards);
                console.log('Cards anteriores:', prevCards);
            
                return [...prevCards, ...newCards];  // Concatena os novos cards
            });
            
    
            setOffset(currentOffset + cardsWithThumbnails.length); // Incrementa o offset
            console.log('Novo offset:', currentOffset + cardsWithThumbnails.length);
    
            setHasMore(cardsWithThumbnails.length === limit); // Verifica se há mais cards
            console.log('Mais cards para carregar?', cardsWithThumbnails.length === limit);
        } catch (error) {
            console.error('Erro ao carregar os cards:', error);
        } finally {
            setIsLoading(false); // Finaliza o estado de carregamento
        }
    }, [filters, offset, hasMore, isLoading]);
    

    const handleFilter = () => {
        loadCards(true); // Carrega os cards com os novos filtros
    };

    const handleInputChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    return (
        <div>
            {/* Substitua o formulário atual pelo FilterForm */}
            <FilterForm
                filters={filters}
                escolas={escolas}
                handleInputChange={handleInputChange}
                handleFilter={handleFilter}
                className="filter"
            />

            {/* Exibição dos Cards */}
            <div className="card-grid">
                {cards.map((card) => (
                    <Card
                        key={card.id}
                        titulo={card.nomeApp}
                        descricao={card.descricao}
                        image={card.thumbnailUrl}
                        escola={card.escola}
                        tutor={card.nomeTutor}
                        integrantes={card.participantes}
                        videoId={card.videoId}
                    />
                ))}
            </div>

            {/* Botão "Carregar mais" */}
            {hasMore && !isLoading && <LoadMoreButton onClick={() => loadCards()} />}
        </div>
    );
};

export default CardsComponent;
