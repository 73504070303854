// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-to-top {
    display: flex;
    justify-content: flex-end;
    right: 100px;
    z-index: 1000;
    width: 100vw;
    margin-right: 30px;
    height: 48px;
    right: 20px;
    margin-bottom: 30px;
}

.scroll-button {
    margin-right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(18, 157, 237);
    cursor: pointer;
    background-color: white;
    margin-bottom: 30px;
    
}

.scroll-button img {
    width: 25px;
    height: 25px;
}

@media (max-width: 768px) {
    .scroll-to-top {
        width: 100vw;
        margin-right: 30px;
    }

    .scroll-button {
        width: 30px;
        height: 30px;
        margin-right: 30px;
    }

    .scroll-button img {
        width: 20px;
        height: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ScrollToTopButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,0CAA0C;IAC1C,eAAe;IACf,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;QACZ,kBAAkB;IACtB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,kBAAkB;IACtB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".scroll-to-top {\n    display: flex;\n    justify-content: flex-end;\n    right: 100px;\n    z-index: 1000;\n    width: 100vw;\n    margin-right: 30px;\n    height: 48px;\n    right: 20px;\n    margin-bottom: 30px;\n}\n\n.scroll-button {\n    margin-right: 30px;\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-shadow: 0px 4px 6px rgba(18, 157, 237);\n    cursor: pointer;\n    background-color: white;\n    margin-bottom: 30px;\n    \n}\n\n.scroll-button img {\n    width: 25px;\n    height: 25px;\n}\n\n@media (max-width: 768px) {\n    .scroll-to-top {\n        width: 100vw;\n        margin-right: 30px;\n    }\n\n    .scroll-button {\n        width: 30px;\n        height: 30px;\n        margin-right: 30px;\n    }\n\n    .scroll-button img {\n        width: 20px;\n        height: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
