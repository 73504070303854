import React, { useState } from 'react';
import './FilterForm.css';

const FilterForm = ({ filters, escolas, handleInputChange, handleFilter }) => {
    // Estado para controlar a visibilidade das opções de escolas   
    const [showSchoolOptions, setShowSchoolOptions] = useState(false);
    const [showSchoolOptions1, setShowSchoolOptions1] = useState(false);

    // Função para alternar a visibilidade das opções de escolas
    const toggleSchoolOptions = () => {
        setShowSchoolOptions(!showSchoolOptions);
    };
    const toggleSchoolOptions1 = () => {
        setShowSchoolOptions1(!showSchoolOptions1);
    };

    // Função para aplicar o filtro automaticamente ao mudar os filtros de escola ou ordenação
    const applyFilter = (name, value) => {
        handleInputChange({ target: { name, value } });
        handleFilter(); // Aplicar o filtro assim que o valor mudar
    };

    return (
        <div className="filter-form">
            <div className="sort-menu">
                <button className="sort-button" onClick={toggleSchoolOptions1}>
                    <span className="sort-icon">🔼</span>
                    Ordenar por
                </button>
                {showSchoolOptions1 && (
                    <ul className="sort-options">
                        <li onClick={() => {
                            applyFilter('ordem', 'ASC');
                            setShowSchoolOptions1(false); // Ocultar após selecionar
                        }}>
                            A-Z
                        </li>
                        <li onClick={() => {
                            applyFilter('ordem', 'DESC');
                            setShowSchoolOptions1(false); // Ocultar após selecionar
                        }}>
                            Z-A
                        </li>
                    </ul>
                )}
            </div>
            <div className='container-filter'>
                <div className="search-bar">
                    <input
                        type="text"
                        name="search"
                        value={filters.search}
                        placeholder="Pesquisar"
                        onChange={handleInputChange} // Mantém a atualização do estado do termo de pesquisa
                        className="search-input"
                    />
                    <button className="search-button" onClick={handleFilter}>🔍</button> {/* Apenas o botão de pesquisa ativa a busca por termo */}
                </div>
                <div className="filter-button-container">
                    {/* Botão de filtro com ícone */}
                    <button className="filter-button" onClick={toggleSchoolOptions}>
                        Selecionar escola
                    </button>
                    {/* Mostrar opções de escolas se showSchoolOptions for true */}
                    {showSchoolOptions && (
                        <ul className="school-options">
                            <li onClick={() => {
                                applyFilter('escola', '');
                                setShowSchoolOptions(false);
                            }}>Todas as Escolas</li>
                            {escolas.map((escola, index) => (
                                <li key={index} onClick={() => {
                                    applyFilter('escola', escola);
                                    setShowSchoolOptions(false);
                                }}>
                                    {escola}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FilterForm;
