import React, { useState } from 'react';
import './Modal.css';
const apiUrl = process.env.REACT_APP_API2_URL || 'http://192.168.7.126:3010';

const Modal = ({ isOpen, onClose, titulo, descricao, escola, integrantes, image }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verifique se o email é válido
        if (!email) {
            alert('Por favor, insira um email válido');
            return;
        }

        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const body = {
                email: email,
                name: email, // Usamos o título como nome da equipe
                equipe: titulo, // Usamos o título como nome da equipe
                category: 'Primerio turno', // Usamos o título como nome da equipe
            };

            const myInit = {
                method: "POST",
                headers: myHeaders,
                mode: "cors",
                cache: "default",
                body: JSON.stringify(body)
            };

            const response = await fetch(`${apiUrl}/api/Votos`, myInit);
            
            if (response.ok) {
                alert('Email enviado com sucesso!');
                setEmail('');
                onClose();
            } else {
                alert('Falha ao enviar o email. Tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao enviar o email:', error);
            alert('Erro ao enviar o email. Verifique sua conexão e tente novamente.');
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <div className="image-container">
                    <img src={image || 'https://via.placeholder.com/800x600'} alt={titulo || "Thumbnail"} className="team-image" />
                </div>
                <div className="modal-body">
                    <h2>{titulo || "Título da Equipe"}</h2>
                    <div className="description">
                        <p><strong>Descrição:</strong> {descricao || "Descrição não disponível"}</p>
                        <p><strong>Escola:</strong> {escola || "Escola não disponível"}</p>
                        <p><strong>Integrantes:</strong> {integrantes || "Integrantes não disponíveis"}</p>
                    </div>
                </div>
                <div className="modal-footer">
                    <form onSubmit={handleSubmit} className='form'>
                        <p>Insira seu email para confirmar o voto:</p>
                        <div className='input-form'>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                            />
                            <button type="submit" className='buttonEnviar'>Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Modal;
