// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-more-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: white;
  color: #00ADEF;
  border: 2px solid #00ADEF;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.load-more-button:hover {
  background-color: #00ADEF;
  color: white;
}

@media (max-width: 500px) {
  .load-more-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadMoreButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,uBAAuB;EACvB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".container-button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.load-more-button {\n  padding: 10px 20px;\n  font-size: 16px;\n  background-color: white;\n  color: #00ADEF;\n  border: 2px solid #00ADEF;\n  border-radius: 25px;\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.load-more-button:hover {\n  background-color: #00ADEF;\n  color: white;\n}\n\n@media (max-width: 500px) {\n  .load-more-button {\n    font-size: 14px;\n    padding: 8px 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
