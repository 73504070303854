// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card {
    box-sizing: border-box;
    background-color: #5ECEED;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    /* Largura da coluna no grid */
    height: 500px;
    /* Altura dos cards */
    margin: 0;
    /* Remove margens externas dos cards */
}

.card img {
    width: 100%;
    /* Largura da imagem ocupa 100% do container */
    height: 300px;
    /* Altura da imagem fixa */
    object-fit: cover;
    /* Ajusta a imagem para cobrir o container */
}

.card-content {
    padding: 16px;
    /* Padding dentro do card */
}

.card-content h3 {
    margin: 0 0 8px 0;
    /* Margem inferior para o título */
}

.card-content p {
    margin: 0;
    /* Margem para o parágrafo */
}
.no-cards-message {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
    padding: 20px;
}
/* Estilo para a mensagem "Em breve" */
.coming-soon-message {
    text-align: center;
    font-size: 1.5rem;
    color: #4facfe; /* Amarelo, por exemplo */
    padding: 20px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/CardComponent.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB;IAChB,WAAW;IACX,8BAA8B;IAC9B,aAAa;IACb,qBAAqB;IACrB,SAAS;IACT,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,8CAA8C;IAC9C,aAAa;IACb,0BAA0B;IAC1B,iBAAiB;IACjB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;IACjB,kCAAkC;AACtC;;AAEA;IACI,SAAS;IACT,4BAA4B;AAChC;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;AACjB;AACA,sCAAsC;AACtC;IACI,kBAAkB;IAClB,iBAAiB;IACjB,cAAc,EAAE,yBAAyB;IACzC,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["\n.card {\n    box-sizing: border-box;\n    background-color: #5ECEED;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    overflow: hidden;\n    width: 100%;\n    /* Largura da coluna no grid */\n    height: 500px;\n    /* Altura dos cards */\n    margin: 0;\n    /* Remove margens externas dos cards */\n}\n\n.card img {\n    width: 100%;\n    /* Largura da imagem ocupa 100% do container */\n    height: 300px;\n    /* Altura da imagem fixa */\n    object-fit: cover;\n    /* Ajusta a imagem para cobrir o container */\n}\n\n.card-content {\n    padding: 16px;\n    /* Padding dentro do card */\n}\n\n.card-content h3 {\n    margin: 0 0 8px 0;\n    /* Margem inferior para o título */\n}\n\n.card-content p {\n    margin: 0;\n    /* Margem para o parágrafo */\n}\n.no-cards-message {\n    text-align: center;\n    font-size: 1.2rem;\n    color: #888;\n    padding: 20px;\n}\n/* Estilo para a mensagem \"Em breve\" */\n.coming-soon-message {\n    text-align: center;\n    font-size: 1.5rem;\n    color: #4facfe; /* Amarelo, por exemplo */\n    padding: 20px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
